import React, { createContext, useEffect, useState } from 'react'

import { dictionaryGlobal } from './apis'

const LanguageContext = createContext()

const transcodeTranslations = (items, langCode) => {
  let items1 = {}
  for(let item of items) {
    items1[item.key] = item[langCode]
  }
  return items1
}

const LanguageProvider = (props) => {
  const { language: languageProp, app, group } = props

  const [translations, setTranslations] = useState(JSON.parse(localStorage.getItem('okeokehu.translations') || '{}'))
  const [language, setLanguage] = useState(localStorage.getItem('okeokehu.selectedlanguage') || 'hu')

  useEffect(() => {
    dictionaryGlobal.getTranslations(app, group, language).then((result) => {
      if(result.success) {
        let newTranslations = transcodeTranslations(result.data, language)
        localStorage.setItem('okeokehu.selectedlanguage', language)
        localStorage.setItem('okeokehu.translations', JSON.stringify(newTranslations))
        setTranslations(newTranslations, language)
      } else {

      }
    })
  }, [language, app, group])

  useEffect(() => {
    if(languageProp != null)
    setLanguage(languageProp)
  }, [languageProp])

  const setSelectedLanguage = (langCode) => {
    setLanguage(langCode)
  }

  return (
    <LanguageContext.Provider
      value={{ translations, language, setSelectedLanguage }}
    >
      {props.children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }

function InfoBox(props) {

    const { headerClass, headerText, contentText, top, left, delay } = props

    return(
        <div className={`info-box-root floating-animation-2 animation-delay${delay} ${headerClass}`} style={{top, left}}>
            <div className="info-box-header font-extraLarge font-bold text-center">{headerText}</div>
            <div className="info-box-content text-center">{contentText}</div>
        </div>
    )
}
export default InfoBox
function InfoBox(props) {

    const { dotColor, headerText, contentText, dotStyle, boxStyle, icon } = props

    return(
        <div className="info-box-root" style={boxStyle}>
            <div className="small-circle"><img src={icon} alt="icon" /></div>
            <div className="text-container">
                <div className={`dot ${dotColor}`} style={dotStyle}></div>
                <div className="info-box-header font-large">{headerText}</div>
                <div className="info-box-content font-small">{contentText}</div>
            </div>
        </div>
    )
}
export default InfoBox
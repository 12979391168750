import ProductCard from './ProductCard'
import useMultilang from 'intl/useMultilang'

function Products() {

    const { getT } = useMultilang()

    return(
        <div className="page products-page" id="products-page">
            <h1 className="text-center">{getT("products.title")}</h1>
            <div className="card-container">
                <ProductCard 
                    icon="assets/bi_phone-v3.png"
                    hoverIcon="assets/bi_phone-v2.png"
                    headerText={getT("products.brandApp.header")}
                    contentText={getT("products.brandApp.content")}
                />
                <ProductCard 
                    icon="assets/fa6-solid_tablet_v2.png"
                    hoverIcon="assets/fa6-solid_tablet_v3.png"
                    headerText={getT("products.kiosk.header")}
                    contentText={getT("products.kiosk.content")}
                />
                <ProductCard 
                    icon="assets/vip_v2.png"
                    hoverIcon="assets/vip.png"
                    headerText={getT("products.vip.header")}
                    contentText={getT("products.vip.content")}
                />
                <ProductCard 
                    icon="assets/bi_qr-code.png"
                    hoverIcon="assets/bi_qr-code-v2.png"
                    headerText={getT("products.qr.header")}
                    contentText={getT("products.qr.content")}
                />
            </div>
        </div>
    )
}
export default Products
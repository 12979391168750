import useMultilang from 'intl/useMultilang'

function Viva() {

    const { getT } = useMultilang()

    return(
        <div className="viva-container">
            <div className="isv-info">{getT("faq.viva.ISVfee")}</div>
            <div className="row">
                <div className="block-container">
                    <div className="block">
                        <div className="number">1</div>
                        <div className="text font-small">{getT("faq.viva.step1")}</div>
                    </div>                    
                    <div className="img-block">
                        <div className="img-container"><img src="assets/Business_account_image.png" alt="Business_account_image" /></div>
                        <div className="header-text font-extraLarge my-1">{getT("faq.viva.businessAccount.header")}</div>
                        <div className="content-text font-small">{getT("faq.viva.businessAccount.content")}</div>
                    </div>
                </div>
                <div className="block-container">
                    <div className="block">
                        <div className="number">2</div>
                        <div className="text font-small">{getT("faq.viva.step2")}</div>
                    </div>
                    <div className="img-block">
                        <div className="img-container"><img src="assets/Digital_debit_card.png" alt="Digital_debit_card" /></div>
                        <div className="header-text font-extraLarge my-1">{getT("faq.viva.digitalCreditcard.header")}</div>
                        <div className="content-text font-small">{getT("faq.viva.digitalCreditcard.content")}</div>
                    </div>
                </div>
                <div className="block-container">
                    <div className="block">
                        <div className="number">3</div>
                        <div className="text font-small">{getT("faq.viva.step3")}</div>
                    </div>
                    <div className="img-block">
                        <div className="img-container"><img src="assets/Mobile_app.png" alt="Mobile_app" /></div>
                        <div className="header-text font-extraLarge my-1">{getT("faq.viva.accountSettings.header")}</div>
                        <div className="content-text font-small">{getT("faq.viva.accountSettings.content")}</div>
                    </div>
                </div>
                <div className="block-container">
                    <div className="block">
                        <div className="number">4</div>
                        <div className="text font-small">{getT("faq.viva.step4")}</div>
                    </div>
                    <div className="img-block">
                        <div className="img-container"><img src="assets/Account_dashboard.png" alt="Account_dashboard" /></div>
                        <div className="header-text font-extraLarge my-1">{getT("faq.viva.mobileApp.header")}</div>
                        <div className="content-text font-small">{getT("faq.viva.mobileApp.content")}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Viva
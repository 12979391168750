import Accordion from "./Accordion"
import useMultilang from 'intl/useMultilang'

function Questions(props) {

    const { getT } = useMultilang()

    return(
        <div className="faq-container">
            <div className="row">
                <div className="col">
                    <h1 className="px-1">{getT("faq.questions.title")}</h1>
                    <Accordion 
                        headerText={getT("faq.questions.box1.header")}
                        contentText={getT("faq.questions.box1.content")}
                    /><br/>
                    <Accordion 
                        headerText={getT("faq.questions.box2.header")}
                        contentText={getT("faq.questions.box2.content")}
                    /><br/>
                    <Accordion 
                        headerText={getT("faq.questions.box3.header")}
                        contentText={getT("faq.questions.box3.content")}
                    /><br/>
                    <Accordion 
                        headerText={getT("faq.questions.box4.header")}
                        contentText={getT("faq.questions.box4.content")}
                    /><br/>
                </div>
                <div className="col">
                    <img className="qmarks-img floating-animation" src="assets/qmarks.png" alt="qmarks" />
                </div>
            </div>
        </div>
    )
}
export default Questions
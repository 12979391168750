import InfoBox from './InfoBox'
import Nav from './Nav'
import { Link } from 'react-scroll'
import useMultilang from 'intl/useMultilang'

function Header() {

    const { getT } = useMultilang()

    return(
        <header className="app-header" id="header-page">
            <div className="app-header-bg bg-pan-left" style={{backgroundImage: "url('/assets/DesignOBject.png')"}}></div>
            <div className="top-bar">
                <div className="img-container"><img src="assets/logo.png" alt="logo" /></div>
                <Nav />
            </div>
            <div className="welcome-root">
                <div className="col col-left puff-in-hor">
                    <h1>{getT("header.welcomeText.part1")}<span className="font-highlighted">{getT("header.welcomeText.part2")}</span></h1>
                    <div className="welcome-text">
                        {getT("header.welcomeText.content")}
                    </div>
                    <div className="row">
                    <Link 
                        to={`products-page`}
                        containerId="main-container"
                        smooth={true}
                        duration={500}
                    >
                        <div className="btn">{getT("header.welcomeText.btn")}</div>
                    </Link>
                    <div className="contacts-link">
                        <div className="icon"><img src="assets/ri_send-plane-fill.png" alt="send_plane"/></div>
                        <div className="text"><a href="mailto:info@okeoke.hu">{getT("header.contact.email")}</a></div>
                    </div>
                    </div>
                </div>
                <div className="col col-right">
                    <div className="box-row slide-in-right">
                    <InfoBox 
                        headerClass="font-dark-blue"
                        headerText={getT("header.infoBox1.header")}
                        contentText={getT("header.infoBox1.content")}
                        contentIconSrc="assets/fa6-solid_tablet.png"
                    />
                    </div>
                    <div className="box-row box-row-left slide-in-left">
                    <InfoBox 
                        headerClass="font-orange"
                        headerText={getT("header.infoBox2.header")}
                        contentText={getT("header.infoBox2.content")}
                        contentIconSrc="assets/bi_phone.png"
                    />
                    </div>
                    <div className="box-row slide-in-right">
                    <InfoBox 
                        headerClass="font-light-blue"
                        headerText={getT("header.infoBox3.header")}
                        contentText={getT("header.infoBox3.content")}
                        contentIconSrc="assets/vip.png"
                    />
                    </div>
                </div>
            </div>
            <div className="shape"></div>
      </header>
    )
}
export default Header
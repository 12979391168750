import CircleBoxes from './CircleBoxes'
import Questions from './Questions'
import Progress from './Progress'
import Viva from './Viva'

function Faq() {
    return(
        <div className="page faq-page" id="faq-page">
            <CircleBoxes />
            <Questions />
            <Progress />
            <Viva />
        </div>
    )
}
export default Faq
import InfoBox from "./InfoBox"
import useMultilang from 'intl/useMultilang'

function CircleBoxes(props) {

    const { getT } = useMultilang()

    return(
        <>
        <h1 className="text-center">{getT("faq.circle.title")}</h1>
        <div className="background-container">
            <div className="inner-background rotate2 animated"></div>
            <div className="inner-circle"></div>
            <InfoBox
                dotColor={"lightish-blue"}
                headerText={getT("faq.circle.infoBox1.header")}
                contentText={getT("faq.circle.infoBox1.content")}
                dotStyle={{right: "120px", top: ""}}
                boxStyle={{top: "35px", left: "-230px", flexDirection: "row-reverse"}}
                icon={"assets/carbon_dashboard.png"}
            />
            <InfoBox
                dotColor={"orange"}
                headerText={getT("faq.circle.infoBox2.header")}
                contentText={getT("faq.circle.infoBox2.content")}
                dotStyle={{top: "", left: "110px"}}
                boxStyle={{top: "35px", right: "-235px"}}
                icon={"assets/bx_line-chart.png"}
            />
            <InfoBox
                dotColor={"black"}
                headerText={getT("faq.circle.infoBox3.header")}
                contentText={getT("faq.circle.infoBox3.content")}
                dotStyle={{top: "", left: "110px"}}
                boxStyle={{top: "330px", right: "-310px"}}
                icon={"assets/ic_round-sell.png"}
            />
            <InfoBox
                dotColor={"light-blue"}
                headerText={getT("faq.circle.infoBox4.header")}
                contentText={getT("faq.circle.infoBox4.content")}
                dotStyle={{top: "110px", left: "50%", transform: "translateX(-50%)"}}
                boxStyle={{bottom: "-210px", left: "50%", transform: "translateX(-50%)", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
                icon={"assets/material-symbols_upgrade-rounded.png"}
            />
            <InfoBox
                dotColor={"dark-blue"}
                headerText={getT("faq.circle.infoBox5.header")}
                contentText={getT("faq.circle.infoBox5.content")}
                dotStyle={{top: "", right: "110px"}}
                boxStyle={{top: "330px", left: "-315px", flexDirection: "row-reverse"}}
                icon={"assets/fa-solid_store.png"}
            />           
            <div className="center-logo animated rotate"><img src="assets/suri.png" alt="suri" /></div>
        </div>
        </>
    )
}
export default CircleBoxes
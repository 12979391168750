import { useState } from 'react'

function ProductCard(props) {

    const { icon, hoverIcon, headerText, contentText } = props

    const [selectedIcon, setSelectedIcon] = useState(icon)
    const [buttonVisible, setButtonVisible] = useState(false) 

    const handleMouseEnter = () => {
        setSelectedIcon(hoverIcon)
        setButtonVisible(true)
    }

    const handleMouseLeave = () => {
        setSelectedIcon(icon)
        setButtonVisible(false)
    }

    return(
        <div className="product-card drop-shadow-type1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
            <div className="icon-container">
                <img src={selectedIcon} alt="icon" />
            </div>
            <h2 className="text-center">{headerText}</h2>
            <p className="text-center font-small">{contentText}</p>
            {/* MÉG LEHET KELL           
            {buttonVisible && <div className="btn">Részletek <span className="img-container"><img src="assets/cil_arrow-right.png" alt="arrow" /></span></div>}
            */}
        </div>
    )
}
export default ProductCard
import useMultilang from 'intl/useMultilang'

function TrustedBy() {

    const { getT } = useMultilang()

    return(
        <div className="page trusted-by-page" id="trusted-by-page">
            <h1 className="text-center">{getT("trustedBy.title")}</h1>
            <div className="partner-container">
                <img src="assets/akvarium.png" alt="akvarium" />
                <img src="assets/UAHUB-LOGO-COL-FULL-LONG-ai.png" alt="airport" />
                <img src="assets/wasabi_logo.png" alt="wasabi" />
                <img src="assets/CRUNCHY LOGO-1.png" alt="crunchy" />
                <img src="assets/ZING_logo.png" alt="zing" />
            </div>
        </div>
    )
}
export default TrustedBy
import useMultilang from 'intl/useMultilang'
import { Link } from 'react-scroll'

function Intro() {

    const { getT } = useMultilang()

    const scrollToElement = (e) => {
        
    }

    return(
        <div className="page intro-page" id="intro-page">
            <h1 className="title" >{getT("intro.title")}</h1>
            <p className="font-small">{getT("intro.subTitle")}</p>
            <div className="row">
                <div className="col col-left image-left">
                    <div className="rect"></div>
                    <div className="img-container">
                        <img src="assets/unsplash_N_Y88TWmGwA.png" alt="guy" />
                    </div>
                </div>
                <div className="col col-right">
                    <h1>{getT("intro.infoBox1.header")}</h1>
                    <p>{getT("intro.infoBox1.content")}</p>
                    <Link
                        to={`products-page`}
                        containerId="main-container"
                        smooth={true}
                        duration={500}
                        spy={true}
                        onSetActive={scrollToElement}
                    >
                        <div className="btn">{getT("intro.infoBox1.btn")}</div>
                    </Link>
                    
                </div>
            </div>
            <div className="row">
                <div className="col col-left">
                    <h1>{getT("intro.infoBox2.header")}</h1>
                        <p>{getT("intro.infoBox2.content")}</p>
                        <Link
                        to={`products-page`}
                        containerId="main-container"
                        smooth={true}
                        duration={500}
                        spy={true}
                        onSetActive={scrollToElement}
                    >
                        <div className="btn">{getT("intro.infoBox2.btn")}</div>
                    </Link>
                    
                </div>
                <div className="col col-right image-right">
                    <div className="rect"></div>
                    <div className="img-container">
                        <img src="assets/card.png" alt="guy" />
                    </div> 
                </div>
            </div>
            <h1 className="title" >{getT("intro.bottomText.header")}</h1>
            <p className="font-small">{getT("intro.bottomText.content")}</p>
        </div>
    )
}
export default Intro
import { Link } from 'react-scroll'
import useMultilang from 'intl/useMultilang'

function Nav() {

    const { getT } = useMultilang()

    const scrollToElement = (e) => {
        
    }

    return(
        <div className="nav-elements font-large">
            <div className="nav-element selected">
                <Link 
                    to={`header-page`}
                    containerId="main-container"
                    smooth={true}
                    duration={500}
                    spy={true}
                    onSetActive={scrollToElement}
                >
                    {getT("header.nav.mainPage")}
                </Link>
            </div>
            <div className="nav-element">
                <Link 
                    to={`products-page`}
                    containerId="main-container"
                    smooth={true}
                    duration={500}
                    spy={true}
                    onSetActive={scrollToElement}
                >
                    {getT("header.nav.brandApp")}
                </Link>
            </div>
            <div className="nav-element">
                <Link 
                    to={`products-page`}
                    containerId="main-container"
                    smooth={true}
                    duration={500}
                    spy={true}
                    onSetActive={scrollToElement}
                >
                    {getT("header.nav.vip")}
                </Link>
            </div>
            <div className="nav-element">
                <Link 
                    to={`products-page`}
                    containerId="main-container"
                    smooth={true}
                    duration={500}
                    spy={true}
                    onSetActive={scrollToElement}
                >
                    {getT("header.nav.kiosk")}
                </Link>
            </div>
            <div className="nav-element btn bounce-top">
                <Link 
                    to={`footer-page`}
                    containerId="main-container"
                    smooth={true}
                    duration={500}
                    spy={true}
                    onSetActive={scrollToElement}
                >
                    {getT("header.nav.contact")}
                </Link>
            </div>
        </div>
    )
}
export default Nav
import { useState } from 'react'

function Accordion(props) {

    const { headerText, contentText } = props

    const [open, setOpen] = useState(false)

    return(
        <div className={`accordion-root ${open ? "open" : "closed"}`}>
            <div className="accordion-header" onClick={() => { setOpen(!open) }}>
                <div className="accordion-header-text">{headerText}</div>
                <div className="accordion-header-icon"><img src="assets/arrow.png" alt="arrow" /></div>
            </div>
            <div className="accordion-content-text">{contentText}</div>
        </div>
    )
}
export default Accordion
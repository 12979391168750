import 'styles/main.scss'
import Header from 'components/Header'
import Intro from 'components/Intro'
import Experience from 'components/Experience'
import Products from 'components/Products'
import TrustedBy from 'components/TrustedBy'
import Footer from 'components/Footer'
import Faq from 'components/Faq'
import { LanguageProvider } from 'intl/languageContext'

function App() {
  return (
    <LanguageProvider app="okeokehu" group='main'>
      <main className="main-container" id="main-container">
        <Header />
        <Intro />
        <Experience />
        <Products />
        <TrustedBy />
        <Faq />
        <Footer />
      </main>
    </LanguageProvider>
  )
}

export default App
import Tilt from 'react-parallax-tilt'

function InfoBox(props) {

    const { headerClass, headerText, contentText, contentIconSrc } = props

    return(
        <Tilt>
            <div className="info-box floating-animation-2">
                <div className={`info-box-header font-large font-bold ${headerClass}`}>{headerText}</div>
                <div className="info-box-content">
                    <div className="text">{contentText}</div>
                    <div className="icon"><img src={contentIconSrc} alt="icon" /></div>
                </div>
            </div>
        </Tilt>
    )
}
export default InfoBox
import InfoBox from "./InfoBox"
import useMultilang from 'intl/useMultilang'

function Progress(props) {

    const { getT } = useMultilang()
    
    return(
        <>
        <h1 className="mb-2 text-center">{getT("faq.progress.title")}</h1>
        <div className="progress-container">
            <div className="progress-bar"></div>
            <InfoBox
                dotColor={"orange"}
                headerText={getT("faq.progress.infoBox1.header")}
                contentText={getT("faq.progress.infoBox1.content")}
                dotStyle={{right: "265px", top: "30px"}}
                boxStyle={{top: "-40px", marginLeft: "295px"}}
                icon={"assets/suri2.png"}
            />
            <InfoBox
                dotColor={"black"}
                headerText={getT("faq.progress.infoBox2.header")}
                contentText={getT("faq.progress.infoBox2.content")}
                dotStyle={{right: "120px", top: "30px"}}
                boxStyle={{top: "0px", left: "0", flexDirection: "row-reverse", marginRight: "295px"}}
                icon={"assets/store.png"}
            />
            <InfoBox
                dotColor={"light-blue"}
                headerText={getT("faq.progress.infoBox3.header")}
                contentText={<><span>{getT("faq.progress.infoBox3.content")}</span><br/><img src="assets/cards.png" alt="icon" /></>}
                dotStyle={{left: "110px", top: "20px"}}
                boxStyle={{top: "45px", left: "0", marginLeft: "295px"}}
                icon={"assets/customer.png"}
            />
            <InfoBox
                dotColor={"dark-blue"}
                headerText={getT("faq.progress.infoBox4.header")}
                contentText={<>{getT("faq.progress.infoBox4.content")}<br/><br/><img src="assets/bi_shield-check.png" alt="icon" /><img src="assets/entypo_price-tag.png" alt="icon" /><img src="assets/bx_support.png" alt="icon" /></>}
                dotStyle={{right: "120px", top: "30px"}}
                boxStyle={{bottom: "-80px", left: "0", flexDirection: "row-reverse", marginRight: "295px"}}
                icon={"assets/VivaWalletBrandLogoVertical.png"}
            />
            <div className="percent-container">
                <div className="circle"><img src="assets/ph_percent-bold.png" alt="percent"/></div>
                <div className="msg-box font-small">{getT("faq.progress.infoBox6.content")}</div>
            </div>
            <InfoBox
                dotColor={"black"}
                headerText={getT("faq.progress.infoBox5.header")}
                contentText={getT("faq.progress.infoBox5.content")}           
                dotStyle={{left: "110px", top: "20px"}}
                boxStyle={{bottom: "-105px", marginLeft: "295px"}}
                icon={"assets/store.png"}
            />
            
        </div>
        
        </>
    )
}
export default Progress
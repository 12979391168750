import { Link } from 'react-scroll'
import useMultilang from 'intl/useMultilang'
//import Tilt from 'react-parallax-tilt'

function Footer() {

    const { getT } = useMultilang()

    return(
        <div className="page footer-page" id="footer-page">
            <div className="app-footer-bg bg-pan-left" style={{backgroundImage: "url('/assets/DesignOBject.png')"}}></div>
            
            <div className="contacts">
            <div className="contact-box">

                     <img src="assets/fruit.png" alt="phone" className='floating-animation'/> 
                    <div className="content">
                        <div className="header font-weight-600">{getT("footer.fruit.label")}</div>
                        <div className="text font-footer-text">{getT("footer.fruit.taxnumber")}</div>
                        <div className="text font-footer-text">{getT("footer.fruitPhonenumber")}</div>
                        <div className="text font-footer-text" >{getT("footer.fruit.emailAddressLabel")}<a className="mailto" href="mailto:support@fruitsys.hu">{getT("footer.fruit.emailAddress")}</a></div>
                        <div className="text font-footer-text">{getT("footer.fruit.officeAddress")}</div>
                    </div>
                   
                </div>
                
                <div className="contact-box center">
                    <div className="img-container"><img src="assets/logo.png" alt="logo" /></div>
                    <p>{getT("footer.upperText")}</p>
                </div>
                
                <div className="contact-box">
                 <img src="assets/okeoke.png" alt="phone" className='floating-animation'/>
                    <div className="content">
                        <div className="header font-weight-600">{getT("footer.okeoke.label")}</div>
                        <div className="text font-footer-text">{getT("footer.okeoke.taxnumber")}</div>
                        <div className="text font-footer-text">{getT("footer.okeokePhonenumber")}</div>
                        <div className="text font-footer-text">{getT("footer.okeoke.emailAddressLabel")}<a className="mailto" href="mailto:info@okeoke.hu">{getT("footer.okeoke.emailAddress")}</a></div>
                        <div className="text font-footer-text">{getT("footer.okeoke.officeAddress")}</div>
                    </div>
                </div>
            </div>
            <div className="navigation">
                <div className="nav-element">
                    <Link 
                        to={`header-page`}
                        containerId="main-container"
                        smooth={true}
                        duration={500}
                    >
                        {getT("footer.nav.mainPage")}
                    </Link>
                </div>
                <div className="nav-element">
                    <Link 
                        to={`products-page`}
                        containerId="main-container"
                        smooth={true}
                        duration={500}
                    >
                        {getT("footer.nav.brandApp")}
                    </Link>
                </div>
                <div className="nav-element">
                    <Link 
                        to={`products-page`}
                        containerId="main-container"
                        smooth={true}
                        duration={500}
                    >
                        {getT("footer.nav.vip")}
                    </Link>
                </div>
                <div className="nav-element">
                    <Link 
                        to={`products-page`}
                        containerId="main-container"
                        smooth={true}
                        duration={500}
                    >
                        {getT("footer.nav.kiosk")}
                    </Link>
                </div>
                <div className="nav-element"><a href="https://download.okeoke.io/docs/policy.pdf" target={"_blank"} rel="noreferrer">{getT("footer.nav.conditions")}</a></div>
                <div className="nav-element"><a href="https://download.okeoke.io/docs/policy.pdf" target={"_blank"} rel="noreferrer">{getT("footer.nav.policy")}</a></div>
            </div>
            <p>{getT("footer.bottomText")}</p>
        </div>
    )
}
export default Footer
import InfoBox from "./InfoBox"
import { Link } from 'react-scroll'
import useMultilang from 'intl/useMultilang'

function Experience() {

    const { getT } = useMultilang()

    return(
        <div className="page exp-page" id="exp-page">
            <div className="shape"></div>
            <div className="col col-left">
                <p className="font-extraLarge font-weight-600">{getT("experience.title")}</p>
                <h1 className="text">{getT("experience.leftText.part1")}<span className="font-highlighted">{getT("experience.leftText.part2")}</span>{getT("experience.leftText.part3")}<span className="font-highlighted">{getT("experience.leftText.part4")}</span></h1>
                <Link 
                    to={`footer-page`}
                    containerId="main-container"
                    smooth={true}
                    duration={500}
                >
                    <div className="btn">{getT("experience.leftText.btn")}</div>
                </Link>
            </div>
            <div className="col col-right">
                <InfoBox 
                    headerClass="highlighted"
                    headerText={getT("experience.rightInfoBox1.header")}
                    contentText={getT("experience.rightInfoBox1.content")}
                    top="12%"
                    left="0%"
                    delay="1"
                />
                <InfoBox 
                    headerClass=""
                    headerText={getT("experience.rightInfoBox2.header")}
                    contentText={getT("experience.rightInfoBox2.content")}
                    top="19%"
                    left="50%"
                    delay="3"
                />
                <InfoBox 
                    headerClass=""
                    headerText={getT("experience.rightInfoBox3.header")}
                    contentText={getT("experience.rightInfoBox3.content")}
                    top="37%"
                    left="17%"
                    delay="2"
                />
                <InfoBox 
                    headerClass=""
                    headerText={getT("experience.rightInfoBox4.header")}
                    contentText={getT("experience.rightInfoBox4.content")}
                    top="48%"
                    left="67%"
                    delay="4"
                />
            </div>
        </div>
    )
}
export default Experience